import http from "../utils/http"

/**
 * 商品详情
 * @param {object} params
 */
export function goodsSkuDetail(params) {
    return http({
        url: "/api/goodssku/detail",
        data: params
    })
}

/**
 * 换购商品列表
 * @param {object} params
 */
export function exchangePage(params) {
    return http({
        url: "/api/get_free_goods/getFreeGoodsList",
        data: params
    })
}